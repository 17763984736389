import { render, staticRenderFns } from "./CyberboardGB.vue?vue&type=template&id=eb944a1e&scoped=true&"
import script from "./CyberboardGB.vue?vue&type=script&lang=ts&"
export * from "./CyberboardGB.vue?vue&type=script&lang=ts&"
import style0 from "./CyberboardGB.vue?vue&type=style&index=0&id=eb944a1e&lang=scss&scoped=true&"
import style1 from "./CyberboardGB.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb944a1e",
  null
  
)

export default component.exports